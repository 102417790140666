import logo from './logo.svg';
import './App.css';
import MyQuery from "./query";
import MyTail from "./MyTail";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <MyQuery/>
          <MyTail/>
      </header>

    </div>
  );
}

export default App;
