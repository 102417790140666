
let token = null;
let headers = new Headers();
const useLocal = false
const useOly = false
const backend_url = useLocal?"http://127.0.0.1:5000":useOly?"https://olyttbackend.sportvis.cn":"https://ttbackend.sportvis.cn"
class BaseApi {
    login = () => new Promise((resolve, reject) => {
        if (this.isLoggingIn) {
            const interval = setInterval(() => {
                if (this.isLoggingIn) return;
                if (token === null || token === undefined) reject(new Error("未能成功登录"));
                else resolve();
                clearInterval(interval);
            }, 100);
        } else {
            this.isLoggingIn = true;
            this.custom_fetch("/v1/usr/login",null, {
                method: "POST",
                body: JSON.stringify({
                    username: '3',
                    password: '123456',
                })
            }).then(res => res.json())
                .then(res => {
                    if (!res.succeed) {
                        reject(new Error("未能成功登录"));
                    } else {
                        token=res.token
                        headers.set("Token", res.token);
                        resolve();
                    }
                    this.isLoggingIn = false;
                })
        }
    });
    custom_fetch(uri,params, init, resType = 'json') {

        return new Promise((resolve, reject) => {
            const itv = setInterval(() => {

                clearInterval(itv);
                const req = fetch(
                    `${backend_url}${uri}`,
                    Object.assign({}, init,{headers})
                );

                if (resType === 'json')
                    resolve(req);
                else
                    resolve(req);
            }, 100);
        });
    }
    request = (input, init, params) => this.login().then(() => this.custom_fetch(input, params, init))
}
let baseApi = new BaseApi()


//endregion

const api = {
    getPlayerNameDictList:()=>{
        return baseApi.request(`/v1/player_name_dict`, null)
            .then(res => res.json())
            .then(res => new Promise((resolve, reject) => {
                if (res.succeed) resolve(res.info);
                else reject(res.info);
            }));
    },

}

export default api;
