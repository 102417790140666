import api from "../api/api";
import styles from './PlayerInfo.module.css';
import React, {useEffect, useState} from 'react';
import {AutoComplete, Col, Row} from 'antd';
const mockVal = (str, repeat = 1) => ({
    value: str.repeat(repeat),
});
const MyQuery = () => {
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [anotherOptions, setAnotherOptions] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState([])
    const [playerDict, setPlayerDict] = useState({})

    const getPanelValue = (searchText) =>
        !searchText ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];
    const onSelect = (data) => {
        console.log('onSelect', data);
    };
    const getPlayerList = async () => {
        try {
            const result_list = await api.getPlayerNameDictList();
            return result_list
        } catch (e) {
            console.log('error',e)
        }
    };
    useEffect(()=> {getPlayerList().then(r => {
        // console.log(r);
        setPlayerList(r.map(a=>{return a.zh_name+":"+a.en_name+":"+a.no}));
        let playerDict = {}
        for(let p=0;p<r.length;p++){
            playerDict[r[p].no] = r[p]
        }
        setPlayerDict(playerDict)
    })}, []);
    return (
        <>
            <h3>球员中英文查询</h3>
            <AutoComplete
                allowClear={true}
                style={{
                    width: '63%',
                    fontSize:'24px',
                    height:'45px',
                    optionFontSize:'24px'
                }}
                onClear={() => {
                    setSelectedPlayer(null)
                }}
                onSelect={(value) => {
                    setSelectedPlayer(playerDict[value.split(":")[2]])
                }}
                dataSource={playerList.map(r => r)}
                filterOption={(inputValue, option) => {
                    return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
                }
                }
                placeholder="输入搜索条件"
            />
            {/*{*/}
            {/*    selectedPlayer? <div style={{width:"60%"}}>*/}
            {/*        <Row>*/}
            {/*            <Col span={12}>{"国家:   "}</Col>*/}
            {/*            <Col span={12} >{selectedPlayer.Nation + " "+selectedPlayer.country}</Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col span={12}>{"性别:   "}</Col>*/}
            {/*            <Col span={12}>{selectedPlayer.gender==="men"?"男":selectedPlayer.gender==="women"?"女":"空"}</Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col span={12}>{selectedPlayer.isRight==="TRUE"?"右手":selectedPlayer.isRight==="FALSE"?"左手":"空"}</Col>*/}
            {/*            <Col span={12}>{selectedPlayer.isStraight==="TRUE"?"直拍":selectedPlayer.isStraight==="FALSE"?"横拍":"空"}</Col>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col span={12}>{selectedPlayer.faceType0}</Col>*/}
            {/*            <Col span={12}>{selectedPlayer.faceType1}</Col>*/}
            {/*        </Row>*/}

            {/*    </div> : null*/}
            {/*}*/}
            <div className={styles.container}>
                {selectedPlayer ? (
                    <>
                        {/*<div className={styles.row}>*/}
                        {/*    <div className={`${styles.col} ${styles.label}`}>{"国家:"}</div>*/}
                        {/*    <div className={styles.col}>{selectedPlayer.Nation + " " + selectedPlayer.country}</div>*/}
                        {/*</div>*/}
                        <div className={styles.row}>
                            {/*<div className={`${styles.col} ${styles.label}`}>{"性别:"}</div>*/}
                            <div className={styles.col}>{selectedPlayer.Nation?(selectedPlayer.Nation + " " + selectedPlayer?.country):"- - "}</div>
                            <div className={styles.col}>
                                {selectedPlayer.gender === "man" ? "男" : selectedPlayer.gender === "woman" ? "女" : "-"}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div
                                className={`${styles.col} ${styles.label}`}>{selectedPlayer.isRight === "TRUE" ? "右手" : selectedPlayer.isRight === "FALSE" ? "左手" : "-"}</div>
                            <div
                                className={`${styles.col} ${styles.label}`}>{selectedPlayer.isStraight === "TRUE" ? "直拍" : selectedPlayer.isStraight === "FALSE" ? "横拍" : "-"}</div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.col}>{selectedPlayer.faceType0}</div>
                            <div className={styles.col}>{selectedPlayer.faceType1}</div>
                        </div>
                    </>
                ) : null}
            </div>
            <h5 style={{position: 'absolute', 'bottom': 20, color: '#4964d7'}}>
                TODO: 球员头像；筛选展示某类型球员；
            </h5>
        </>
    );
};
export default MyQuery;
