import React from 'react';
export default function MyTail ()  {


    return (<div style={{zIndex:100,position:"absolute",bottom:0,width:'80%',height:'30px'}}>
        <div style={{
            width: '100%',
            position: "absolute",
            height: 30,
            bottom: 0,
            padding: '2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

            <div style={{position: 'absolute', bottom: 4, fontSize: '9px' , display:'flex'}}>
                <span style={{color: "white", margin: '4px'}}>Powered by ZJUIDG</span>
                <img src={'https://beian.mps.gov.cn/img/logo01.dd7ff50e.png'} alt={'beian'}
                     style={{objectFit: "contain", width: '10px'}}/>
                <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011002017572" target="_blank"
                   style={{color: '#fff', margin: '4px'}}>浙公网安备33011002017572号</a>
                <span>&nbsp;</span>
                <a href="https://beian.miit.gov.cn/" target="_blank"
                   style={{color: '#fff', margin: '4px'}}>&nbsp;浙ICP备2023048561号-1</a>
            </div>
        </div>


    </div>)

}
